// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You"re encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it"ll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import $ from "jquery"
import SimpleLightbox from "simple-lightbox"
SimpleLightbox.registerAsJqueryPlugin($);

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).on("turbolinks:load", function() {
  $(".menu-gallery-desktop .gallery-link").simpleLightbox({
    showCaptions: false,
    loadingTimeout: 0,
    nextOnImageClick: true
  });

  $(".menu-gallery-mobile .gallery-link").simpleLightbox({
    showCaptions: false,
    loadingTimeout: 0,
    nextOnImageClick: true
  });

  $(".gallery-one .gallery-link").simpleLightbox({
    showCaptions: false,
    loadingTimeout: 0,
    nextOnImageClick: true
  });

  $(".gallery-two .gallery-link").simpleLightbox({
    showCaptions: false,
    loadingTimeout: 0,
    nextOnImageClick: true
  });

  $(".gallery-three .gallery-link").simpleLightbox({
    showCaptions: false,
    loadingTimeout: 0,
    nextOnImageClick: true
  });

  $(".gallery-four .gallery-link").simpleLightbox({
    showCaptions: false,
    loadingTimeout: 0,
    nextOnImageClick: true
  });

  $(".sponsor-gallery-link").simpleLightbox({
    showCaptions: false,
    loadingTimeout: 0,
    nextOnImageClick: true
  });

  $(".instructions-link").simpleLightbox({
    showCaptions: false,
    loadingTimeout: 0,
    nextOnImageClick: true
  });

  $(".menu-overlay-open-link").click(function(e){
    e.preventDefault()
    $(".menu-overlay").fadeToggle("fast");
  });

  $(".menu-overlay-close-link").click(function(e){
    e.preventDefault()
    $(".menu-overlay").fadeToggle("fast");
  });
})

